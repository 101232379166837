import { chatExpertsSort } from '@/api/experts'
const state = {
  // 专家分类
  roleSort: JSON.parse(sessionStorage.getItem('roleSort')) || []
}
const mutations = {
  setRoleSort (state, value) {
    state.roleSort = value
    sessionStorage.setItem('roleSort', JSON.stringify(value))
  }
}
const actions = {
  // 获取角色分类
  async getRoleSort ({ commit }) {
    try {
      const { data } = await chatExpertsSort()
      commit('setRoleSort', Object.freeze(data))
    } catch (err) {
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
