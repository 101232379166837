// 导入实例
import Vue from 'vue'
// 注册组件
import hlInput from '@/mobileComponents/input/input.vue'
import hlForm from '@/mobileComponents/form/form.vue'
import hlFormItem from '@/mobileComponents/form_item/form_item.vue'
import hlButton from '@/components/button/button.vue'
// import {
//   Button,
//   Container,
//   Aside,
//   Popconfirm,
//   Main,
//   Dialog,
//   Input,
//   Dropdown,
//   DropdownMenu,
//   DropdownItem,
//   Collapse,
//   CollapseItem,
//   Form,
//   FormItem,
//   Select,
//   Option,
//   Alert,
//   MessageBox,
//   Notification,
//   Loading,
//   Message,
//   Radio,
//   Popover,
//   Empty,
//   Tooltip,
//   Upload,
//   DatePicker,
//   Pagination,
//   Table,
//   TableColumn,
//   Drawer
// } from 'element-ui'
// // 全局变量
// Vue.prototype.$msgbox = MessageBox
// Vue.prototype.$alert = MessageBox.alert
// Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$notify = Notification
// Vue.prototype.$loading = Loading.service
// Vue.prototype.$message = Message
// // 全局组件
Vue.component('hl-input', hlInput)
Vue.component('hl-form', hlForm)
Vue.component('hl-form-item', hlFormItem)
Vue.component('hl-button', hlButton)
// Vue.component('el-button', Button)
// Vue.component('el-container', Container)
// Vue.component('el-aside', Aside)
// Vue.component('el-popconfirm', Popconfirm)
// Vue.component('el-main', Main)
// Vue.component('el-dialog', Dialog)
// Vue.component('el-input', Input)
// Vue.component('el-dropdown', Dropdown)
// Vue.component('el-dropdown-menu', DropdownMenu)
// Vue.component('el-dropdown-item', DropdownItem)
// Vue.component('el-collapse', Collapse)
// Vue.component('el-collapse-item', CollapseItem)
// Vue.component('el-form', Form)
// Vue.component('el-form-item', FormItem)
// Vue.component('el-select', Select)
// Vue.component('el-option', Option)
// Vue.component('el-alert', Alert)
// Vue.component('el-radio', Radio)
// Vue.component('el-empty', Empty)
// Vue.component('el-popover', Popover)
// Vue.component('el-tooltip', Tooltip)
// Vue.component('el-upload', Upload)
// Vue.component('el-date-picker', DatePicker)
// Vue.component('el-pagination', Pagination)
// Vue.component('el-table', Table)
// Vue.component('el-table-column', TableColumn)
// Vue.component('el-drawer', Drawer)

// Vue.use(Loading)
// Vue.use(Loading.directive)
