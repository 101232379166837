<template>
  <div class="pc-button">
    <!-- one -->
    <button class="button_one" v-if="type==='rainbow'">
      <span>
        <slot></slot>
      </span>
    </button>
    <button class="button_two" v-else-if="type==='aurora'">
      <span>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <slot></slot>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'PCButton',
  props: {
    type: {
      type: String,
      default: 'rainbow'
    }
  }
}
</script>

<style lang="less" scoped>
  @import url('./button.less');
</style>
