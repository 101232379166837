const state = {
  pdfchatRecode: JSON.parse(localStorage.getItem('pdfchatRecode')) || []
}

const mutations = {
  setPdfChat (state, val) {
    state.pdfchatRecode = val
    localStorage.setItem('pdfchatRecode', JSON.stringify(val))
  },
  setPdfItem (state, item) {
    const allRecode = state.pdfchatRecode
    const index = allRecode.findIndex(i => i.id === item.id)
    if (index !== -1) {
      allRecode[index] = item
    } else {
      allRecode.push(item)
    }
    state.pdfchatRecode = allRecode
    localStorage.setItem('pdfchatRecode', JSON.stringify(allRecode))
  },
  setPdfItemRecode (state, { id, recode }) {
    const allRecode = state.pdfchatRecode
    const index = allRecode.findIndex(i => i.id === id)
    if (index !== -1) {
      allRecode[index].chatRecode = recode
    }
    state.pdfchatRecode = allRecode
    localStorage.setItem('pdfchatRecode', JSON.stringify(allRecode))
  },
  deletePdfChatItem (state, id) {
    const recode = state.pdfchatRecode
    const index = recode.findIndex(i => i.id === id)
    if (index !== -1) {
      recode.splice(index, 1)
      state.pdfchatRecode = recode
      localStorage.setItem('pdfchatRecode', JSON.stringify(recode))
    }
  }

}

export default {
  namespaced: true,
  state,
  mutations
}
