
// 防抖
export function debounce (func, delay) {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}
// 节流
export function throttle (func, delay, immediate = false) {
  let canRun = true

  if (immediate) {
    return function (...args) {
      if (!canRun) return
      func.apply(this, args)
      canRun = false
      setTimeout(() => {
        canRun = true
      }, delay)
    }
  }

  return function (...args) {
    if (!canRun) return
    canRun = false
    setTimeout(() => {
      func.apply(this, args)
      canRun = true
    }, delay)
  }
}

// 找到href 参数
export function getQueryVariable (variable) {
  const query = window.location.search.substring(1) || window.location.href
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}
