// 区分手机和电脑 用来做提示信息
import utils from './utils'
const isMobile = utils.isMobile()
let Message = null
export default function tip ({ message, type }) {
  if (Message === null) {
    Message = isMobile ? require('vant').Notify : require('element-ui').Message
  }
  type = /error|danger/.test(type) ? (isMobile ? 'danger' : 'error') : type
  Message({ message, type })
}
