import request from '@/utils/request'
// 插件列表
export function chatPlugins (params) {
  return request({
    url: '/chat/plugins',
    method: 'get',
    params
  })
}
// 安装插件
export function installPluginApi (data) {
  return request({
    url: '/chat/user/install-plugin',
    method: 'post',
    data
  })
}
// 卸载插件
export function unInstallPluginApi (data) {
  return request({
    url: '/chat/user/uninstall-plugin',
    method: 'post',
    data
  })
}
// 用户插件信息
export function userPluginInfo (params) {
  return request({
    url: '/chat/user/plugins',
    method: 'get',
    params
  })
}

// 上传图片获取七牛云token
export function getQiniuToken (params) {
  return request({
    url: '/chat/help/qiniu-token',
    method: 'get',
    params
  })
}

// 更新用户聊天记录
export function setChatUserChatRecord (data) {
  return request({
    url: '/chat/user/chat-record',
    method: 'POST',
    data
  })
}

// 获取用户聊天记录
export function getChatUserChatRecord (params) {
  return request({
    url: '/chat/user/chat-record',
    method: 'GET',
    params
  })
}

// 图片识别 通用文字
export function imageOCRgeneral (data) {
  return request({
    url: '/chat/ocr/recognize-general',
    method: 'post',
    data
  })
}
// 图片识别 题目
export function imageOCRMath (data) {
  return request({
    url: '/chat/ocr/recognize-edu-question',
    method: 'post',
    data
  })
}
// 图片识别 表格
export function imageOCRTable (data) {
  return request({
    url: '/chat/ocr/recognize-table',
    method: 'post',
    data
  })
}
// 4.0套餐包列表
export function orangePack (params) {
  return request({
    url: '/chat/plugins/pack/levels',
    method: 'get',
    params
  })
}
// 创建4.0套餐包订单
export function createPackOrder (data) {
  return request({
    url: '/chat/order/create-pack-order',
    method: 'post',
    data
  })
}
// 4.0套餐包日志记录
export function orangeLogs (params) {
  return request({
    url: '/chat/plugins/pack/logs',
    method: 'get',
    params
  })
}

// 联网搜索
export function chatSearch (params, signal) {
  return request({
    url: '/chat/search/bing',
    method: 'get',
    params,
    timeout: 20000,
    signal
  })
}

// 插件试用领取
export function chatPluginsTrial (data) {
  return request({
    url: '/chat/plugins/trial',
    method: 'POST',
    data
  })
}
// 插件订阅等级列表
export function chatPluginsSubscribeLevels (params) {
  return request({
    url: '/chat/plugins/subscribe/levels',
    method: 'GET',
    params
  })
}

// 创建插件订阅订单
export function chatOrderCreateSubscribeOrder (data) {
  return request({
    url: '/chat/order/create-subscribe-order',
    method: 'POST',
    data
  })
}
