import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
// 初始化 PDF 导出
const scale = window.devicePixelRatio
export default (_this, element, title) => {
  if (_this.isCapture) return
  _this.isCapture = true
  const index = _this.pluginData.findIndex(item => item.id === 5)
  _this.pluginData[index].isEnable = false
  _this.isPopup = false
  _this.pluginData[index].loading = true
  if (!element) {
    _this.isCapture = false
    _this.pluginData[index].loading = false
    _this.$message({
      message: '当前无聊天记录'
    })
    return
  }
  // const element = document.getElementById(`${id}`)
  const opts = {
    // scale: 12, // 缩放比例，提高生成图片清晰度
    useCORS: true, // 允许加载跨域的图片
    height: element.offsetHeight, // 画布高度
    width: element.offsetWidth, // 画布宽度
    allowTaint: false, // 允许图片跨域，和 useCORS 二者不可共同使用
    tainttest: true, // 检测每张图片已经加载完成
    dpi: window.devicePixelRatio,
    scale: scale
  }
  html2canvas(element, opts)
    .then((canvas) => {
      const contentWidth = canvas.width
      const contentHeight = canvas.height
      // 一页pdf显示html页面生成的canvas高度;
      const pageHeight = (contentWidth / 592.28) * 841.89
      // 未生成pdf的html页面高度
      let leftHeight = contentHeight
      // 页面偏移
      let position = 0
      // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 595.28
      const imgHeight = (592.28 / contentWidth) * contentHeight
      const pageData = canvas.toDataURL('image/png', 1)
      // console.log(pageData)
      // a4纸纵向，一般默认使用；new JsPDF('landscape'); 横向页面
      const PDF = new JsPDF('', 'pt', 'a4')

      // 当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
      // addImage(pageData, 'JPEG', 左，上，宽度，高度)设置
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
      // 超过一页时，分页打印（每页高度841.89）
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= 841.89
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }
      PDF.save(title + '.pdf')
      _this.isCapture = false
      _this.pluginData[index].loading = false
    })
    .catch((error) => {
      console.log('打印失败', error)
      _this.isCapture = false
      _this.pluginData[index].loading = false
    })
}
