<template>
  <div id="app">
    <!-- 动态缓存 -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="child-view"></router-view>
    </keep-alive>
    <!-- 路由出口 -->
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- 登录组件 -->
    <Login v-if="!isMobile()" />
    <!-- 客服PC -->
    <!-- <customer-service v-if="!isMobile()"/> -->
    <!-- 客服YD -->
    <!-- <yd-customer-service v-else-if="isMobile()&&$route.meta.fatherPath==='/login'"/> -->
    <!-- 清除聊天记录 -->
    <clear-record v-if="$store.state.isFull"></clear-record>
  </div>
</template>

<script>
import { chatWxOfficalLogin, chatUserInfo } from '@/api/login'
import utils from '@/utils/utils'
export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: 'home'
      },
      {
        name: 'description',
        content: 'This is the home page'
      }
    ]
  },
  components: {
    Login: () => import('@/components/login/login.vue'),
    // customerService: () => import('@/components/customer_service/customer_service.vue')
    // ydCustomerService: () => import('@/mobileComponents/customer_service/customer_service.vue')
    clearRecord: () => import('@/components/plugin/clearRecode/clearRecord.vue')
  },
  data () {
    return {
      time: null
    }
  },
  created () {
    // 检查环境是桌面版还是网页版
    this.checkDevice()
    // 设置版本号
    this.$store.commit('setEdition', '1.4.0')

    // 获取用户聊天记录
    if (this.$store.state.token && this.$store.state.isRenew) {
      this.searchChatRecord(this.$store.state.userInfo.username, this.$store.state.userInfo.vip_level)
    } else if (this.$store.state.token) {
      new Promise(this.monitorUser).then((res) => {
        if (!res) {
          this.searchChatRecord(this.$store.state.userInfo.username, this.$store.state.userInfo.vip_level)
        }
      })
    }
  },
  mounted () {
    // 监听子窗口传递的参数
    window.addEventListener('message', event => {
      if (event.data.isBinding) {
        this.$store.commit('user/setWxCode', event.data.wxCode)
        this.$store.commit('user/setIsBinding', event.data.isBinding)
      }
    })
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    // 检查环境
    checkDevice () {
      const href = location.href
      const env = /device=(\w+)[&]?/.exec(href)
      let cid = /cid=(\d+)[&]?/.exec(href)
      if (env) {
        this.$store.commit('config/setDevice', env[1])
      }
      cid = cid ? cid[1] : this.cid
      if (cid === '3115' || cid === '23113' || cid === '3111' || cid === '3114') {
        this.$store.commit('config/setDevice', 'desktop')
      }
    },
    ...utils,
    // 公众号登录
    publicSign (code) {
      // 开启移动端 登录等待
      this.$store.commit('login/setLoginLoad', true)
      chatWxOfficalLogin({
        code,
        channel: this.$route.query.cid,
        platform: 'offical-account'
      }).then((res) => {
        this.$store.commit('setToken', res.data.token)
        if (utils.isMobile()) {
          this.$router.push('/chat_room')
        } else {
          this.$store.commit('login/setIsShow', false)
        }
        if (!this.$store.state.isRenew) {
          new Promise(this.monitorUser).then((bol) => {
            if (!bol) {
              this.searchChatRecord(res.data.username, res.data.vip_level)
            }
          })
        } else {
          this.searchChatRecord(res.data.username, res.data.vip_level)
        }
      }).catch(() => {
        this.$store.commit('login/setLoginLoad', false)
      }).finally(() => {
        if (this.$store.state.token) {
          // 获取用户信息
          this.getUserInfo().then(() => {
            this.$store.commit('login/setLoginLoad', false)
          })
        }
      })
    },
    // 获取用户信息
    async getUserInfo () {
      const res = await chatUserInfo()
      this.$store.commit('setUserInfo', res.data)
    },
    // 查询当前用户的聊天记录
    searchChatRecord (userName, level) {
      const str = this.$md5(userName)
      this.$store.commit('setChatRecordName', 'chat_' + str)
      let chatRecord = JSON.parse(localStorage.getItem('chat_' + str))
      if (!chatRecord || chatRecord.length <= 0) {
        const chatRoom = [
          {
            id: '2023' + Date.now().toString().slice(-7),
            chatRecord: [],
            messageArr: [],
            chatMessage: 'chat room',
            chatModel: level !== 1 ? '3.5-turbo' : '3.5',
            prompt: '',
            createTime: Date.now()
          }
        ]
        this.$store.commit('setAllchatRecord', chatRoom)
        return
      }
      // const recode = this.emptyChatRoom(this.$store.state.AllchatRecord)
      if (chatRecord.length > 0 && this.$store.state.token) {
        chatRecord = this.emptyChatRoom(chatRecord)
      }
      this.$store.commit('setAllchatRecord', chatRecord)
    },
    // 监测用户信息是否存在
    monitorUser (resolve) {
      this.time = setInterval(() => {
        if (this.$store.state.userInfo && this.$store.state.userInfo.username) {
          this.$store.commit('setChatRecordName', 'chat_' + this.$md5(this.$store.state.userInfo.username))
          const AllchatRecord = JSON.parse(localStorage.getItem('AllchatRecord'))
          // 重新设置聊天格式(增加聊天室创建时间)
          if (AllchatRecord && AllchatRecord.length) {
            AllchatRecord.forEach((item, index) => {
              if (!item.createTime) {
                AllchatRecord[index].createTime = Date.now()
              }
            })
            this.$store.commit('setAllchatRecord', AllchatRecord)
            this.$store.commit('setIsRenew', true)
            this.$store.commit('setRoomId', AllchatRecord[0].id)
            resolve(true)
          } else {
            resolve(false)
          }
          clearInterval(this.time)
        }
      }, 0)
    },
    // 去除空聊天室
    emptyChatRoom (res) {
      let arr = JSON.parse(JSON.stringify(res))
      arr = arr.filter((item, index) => item.chatRecord.length > 0 || index === 0)
      return arr
    }
  },
  watch: {
    $route: {
      handler (val, oldval) {
        const url = window.location.search
        const location = window.location.href
        // 绑定微信code
        if (/state=wx/.test(url)) {
          // 关闭当前窗口
          window.close()
          const targetWindow = window.opener || window.parent
          targetWindow.postMessage(
            {
              isBinding: true,
              wxCode: url
                .split('&')[0]
                .split('?')[1]
                .split('=')[1]
            },
            window.location.href
          )
        }
        // 公众号登录
        if (/state=gzh/.test(url) && !this.$store.state.token) {
          let urlSearch = window.location.search.split('?')[1]
          urlSearch = urlSearch.split('&')
          let code = null
          urlSearch.forEach((item) => {
            if (/code/.test(item)) {
              code = item.split('=')[1]
            }
          })
          this.publicSign(code)
        }
        // 获取备案号
        if (/gptai.lingwl.cn/.test(location)) {
          this.$store.commit('setFilings', '黔ICP备2022009066号')
        } else if (/chat.fanwenct.com/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2022121883号')
        } else if (/chatai.shrwei3.top/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2023007511号')
        } else if (/chatgpt.shrwei3.top/.test(location)) {
          this.$store.commit('setFilings', '粤ICP备2023007511号')
        } else {
          this.$store.commit('setFilings', '粤ICP备18058968号')
        }
        if (val.query.invite_code) {
          // 分享邀请注册
          this.$store.commit('setInvitationCode', val.query.invite_code)
        }
        if (/cid=/.test(url)) {
          const strs = url.split('&')
          let str = ''
          strs.forEach((item, index) => {
            if (/cid/.test(item)) {
              str = item.split('=')[1]
            }
          })
          // 获取来源渠道
          this.$store.commit('setCid', str)
        }
        if (val.query.cid) {
          // 获取来源渠道
          this.$store.commit('setCid', val.query.cid)
        }
      }
    },
    '$store.state.token': {
      handler (val) {
        // console.log(val, 'val')
        if (!val) {
          const id = '2023' + Date.now().toString().slice(-7)

          this.$store.state.AllchatRecord = [
            {
              id,
              chatRecord: [],
              messageArr: [],
              chatMessage: 'chat room',
              prompt: '',
              isNot: true
            }
          ]
          this.$store.commit('setRoomId', id)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
#app {
}
</style>
