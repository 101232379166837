<template>
  <div class="yd-form_item hl-form_item" ref="form_item">
      <!-- 插槽 -->
      <slot ></slot>
  </div>
</template>

<script>
export default {
  name: 'YDFormItem',
  props: {
    prop: {
      type: String
    }
  },
  inject: ['hlForm'],
  provide () {
    return {
      formItem: this,
      form: this.hlForm
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./form_item.less');
</style>
