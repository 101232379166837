import { userPluginInfo, chatPlugins } from '@/api/plugin'
const state = {
  pluginList: [],
  pluginInfo: JSON.parse(localStorage.getItem('pluginInfo')) || [],
  // 是否显示云端上传
  isCloud: false,
  // 是否开启联网搜索
  isOpen: JSON.parse(localStorage.getItem('isOpen')) || true,
  // 是否显示插件详细
  isPluginDetail: false,
  // 当前展示插件的数据
  pluginData: {},
  // 当前插件详情的taber
  taberType: 'use' // use 使用 subscribe 订阅 set 设置
}
const mutations = {
  setNetword (state, val) {
    state.isOpen = val
    localStorage.setItem('isOpen', val)
  },
  setPluginList (state, val) {
    state.pluginList = val
  },
  setPluginInfo (state, val) {
    state.pluginInfo = val
    localStorage.setItem('pluginInfo', JSON.stringify(val))
  },
  installPlugin (state, { id, name, params }) {
    // 联网开启关闭状态
    if (id === 7) {
      params.isOpen = true
      state.isOpen = true
      localStorage.setItem('isOpen', true)
    }
    state.pluginInfo.push({ id, name, params })
    localStorage.setItem('pluginInfo', JSON.stringify(state.pluginInfo))
  },
  unInstallPlugin (state, id) {
    state.pluginInfo = state.pluginInfo.filter(i => i.id !== id)
    localStorage.setItem('pluginInfo', JSON.stringify(state.pluginInfo))
  },
  setisCloud (state, value) {
    state.isCloud = value
  },
  setEngine (state, value) {
    const index = state.pluginInfo.findIndex(i => i.id === 7)
    if (index === -1) return
    state.pluginInfo[index].params = value
    localStorage.setItem('pluginInfo', JSON.stringify(state.pluginInfo))
  },
  setIsPluginDetail (state, value) {
    state.isPluginDetail = value
  },
  setPluginData (state, value) {
    state.pluginData = value
  },
  setTaberType (state, value) {
    state.taberType = value
  }
}
const actions = {
  async getUserPlugin ({ commit, state }) {
    try {
      const { data } = await userPluginInfo()
      const pluginInfo = data.map(i => {
        const params = JSON.parse(i.params)
        // 联网开启关闭状态
        if (i.id === 7) {
          params.isOpen = state.isOpen
        }
        return {
          id: i.id,
          params,
          name: i.name,
          expire_time: i.expire_time,
          plugin_flag: i.plugin_flag
        }
      })
      commit('setPluginInfo', pluginInfo)
    } catch (err) {
      console.log('获取用户插件失败')
    }
  },
  // 获取插件列表
  async getPluginList ({ commit }) {
    try {
      const { data } = await chatPlugins()
      const pluginList = data.map(item => {
        item.params = item.params && JSON.parse(item.params)
        return item
      })
      commit('setPluginList', pluginList)
    } catch (err) {
      console.log('获取插件列表失败')
    }
  },
  // 改变联网搜索状态
  changeNetworkState ({ state, commit }, val) {
    state.isOpen = val
    commit('setNetword', val)
    const pluginInfo = state.pluginInfo
    const index = pluginInfo.findIndex(i => i.id === 7)
    if (index !== -1) {
      pluginInfo[index].params.isOpen = val
      commit('setPluginInfo', pluginInfo)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
