import request from '@/utils/request'

// 热门专家
export function chatExpertsHots (params) {
  return request({
    url: '/chat/experts/hots',
    method: 'get',
    params
  })
}
// 专家分类
export function chatExpertsSort (params) {
  return request({
    url: '/chat/experts/categories',
    method: 'get',
    params
  })
}
// 专家列表
export function chatExperts (params) {
  return request({
    url: '/chat/experts',
    method: 'get',
    params
  })
}
// 专家使用上报
export function chatExpertsUsage (data) {
  return request({
    url: '/chat/experts/usage',
    method: 'post',
    data
  })
}

// 专家提问示例
export function chatExpertQuestions (params) {
  return request({
    url: '/chat/expert/questions',
    method: 'get',
    params
  })
}
