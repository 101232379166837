const state = {
  // 加入微信聊天群显示与隐藏
  isWxPopup: false,
  // 收藏引导
  isguide: false
}
const mutations = {
  setIsWxPopup (state, value) {
    state.isWxPopup = value
  },
  setIsguide (state, value) {
    state.isguide = value
  }
}
const actions = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
